@import "../.././Variables.scss";

.photography-image {
  width: 100%;
  padding: $tabSpacing;
}

.photo-caption {
  margin-top: $tabSpacing !important;
  margin-bottom: 3*$tabSpacing !important;
}

.photography-wrapper {
  margin-top: $gutterSpacing;
  margin-bottom: $gutterSpacing;
}