@import "../.././Variables.scss";


.headshot-image
{
  border-radius: 50%;
  max-width: 250px;
}



.nav-title-container
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 2.5*$tabSpacing;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .nav-title-container
  {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.nav-link-active
{
  font-weight: 700;
}

.nav-menu-wrapper
{
  margin-left: auto;
}

.nav-content-container
{
  display: flex;
  height: auto;
	justify-content: flex-start;
  align-items: center;
}

.top-nav-link-container
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .top-nav-link-container
  {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
  }
}


@media only screen and (max-width: 768px) {
  .nav-title-container
  {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
  }
}


.nav-text-container
{
  align-self: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  margin-right: $tabSpacing;
  margin-left: 2*$tabSpacing;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .nav-text-container
  {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.nav-link-container-wrapper
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // margin-right: auto;
  
  flex-wrap: wrap;
}

.nav-link-container
{
  margin-left: 2.5px;
  margin-right: 2.5px;
}

@media only screen and (max-width: 768px) {
  .nav-link-container
  {
    margin-left: 0px;
    margin-right: 0px;
  }
}



.nav-link
{
  text-decoration: none;
  color: $primaryBlack;
}

.nav-icon-container
{
  display: flex;
  height: 100%;
}

.nav-icon
{
  align-self: center;
}

.nav-spacer
{
  color: $primaryColor !important;
  margin-left: 2*$tabSpacing;
  margin-right: 2*$tabSpacing;
}

@media only screen and (max-width: 768px) {
  .nav-spacer
  {
    margin-left: 1.5*$tabSpacing;
    margin-right: 1.5*$tabSpacing;
  }
}
