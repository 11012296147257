@import "../.././Variables.scss";

.footer-wrapper
{
    margin-top: auto;
    min-height: 100px;
}

.footer-content-container
{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: flex-start;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .footer-content-container
    {
        justify-content: center;
    }
}

// @media only screen and (max-width: 768px) {
//     .footer-content-container
//     {   
//         justify-content: center;
//         align-items: center;
//     }
// }

.footer-text-container
{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.footer-attribution
{
    margin-bottom: $tabSpacing;
}


.footer-icons-container
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%
}
@media only screen and (max-width: 768px) {
    .footer-icons-container
    {   
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}